<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <RouterLink :to="{ name: 'Home' }" class="navbar-brand" href="#">BetML</RouterLink>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="store.loggedInUser" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <RouterLink :to="{ name: 'Home' }" class="nav-link" aria-current="page" href="#">Home</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink :to="{ name: 'DataExplorer' }" class="nav-link" href="#">Data explorer</RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid">
    <RouterView />
  </div>
</template>

<script setup>
import axios from 'axios';
import { onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useUsersStore } from './store/usersStore';

const router = useRouter();

const store = useUsersStore()

const state = reactive({ originalRoute: null, loading: true });

router.beforeEach(async (to) => {
  if (state.loading) {
    state.originalRoute = to;
  }

  if (!state.loading && !store.getLoggedInUser && !to.meta.guest) {
    // redirect the user to the login page
    return { name: 'Login' }
  }
})

onBeforeMount(async () => {
  try {
    const { data } = await axios.get('/api/v1/users/auth/me');

    store.setLoggedInUser(data);

    if (state.originalRoute) {
      router.replace(state.originalRoute);
    }
  } catch (e) {
    console.error(e);

    if (state.originalRoute) {
      if (state.originalRoute.meta.guest) {
        router.replace(state.originalRoute);
      } else {
        router.replace({ name: 'Login' });
      }
    }
  } finally {
    state.loading = false;
  }
});
</script>

<style></style>
