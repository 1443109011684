<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <form>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1"
                            v-model="passwordResetForm.password">
                    </div>

                    <button type="button" class="btn btn-primary" :disabled="passwordResetForm.submitting"
                        @click="setPassword">Set password</button>
                </form>
            </div>
        </div>
    </div>

</template>

<script setup>
import axios from 'axios';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();

const route = useRoute();

const { token } = route.params;

const passwordResetForm = reactive({
    password: '',
    submitting: false,
});

const setPassword = async () => {
    passwordResetForm.submitting = true;
    try {
        await axios.put(`/api/v1/users/invite/${token}/confirm`, { password: passwordResetForm.password });

        router.replace({ name: 'Login' });
    } finally {
        passwordResetForm.submitting = false;
    }
}
</script>