import { createRouter, createWebHistory } from "vue-router";

import HomeView from "./views/HomeView.vue";
import DataExplorerView from "./views/DataExplorerView.vue";
import LoginView from "./views/LoginView.vue";
import PasswordResetForm from "./views/PasswordResetForm.vue";

const routes = [
  { name: "Home", path: "/", component: HomeView },
  { name: "DataExplorer", path: "/data-explorer", component: DataExplorerView },
  {
    name: "Login",
    path: "/login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    name: "PasswordReset",
    path: "/users/invite/:token",
    component: PasswordResetForm,
    meta: {
      guest: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
