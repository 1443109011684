<template>
    <div class="row mt-4">
        <div class="col-6">
            <h4>Raw files</h4>
            <div v-if="viewState.rawFilesList.loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <ul v-else class="list-group">
                <!-- <li class="list-group-item">Restore process state: {{ viewState.lastRestoreProcess.status }}
                    <span v-if="viewState.lastRestoreProcess.status === 'inProgress'">
                        - {{
                viewState.lastRestoreProcess.restored }} restored of {{ viewState.lastRestoreProcess.totalFiles
                        }}
                    </span>
                </li>-->
                <li class="list-group-item">Last sync date: {{ viewState.rawFilesList.lastSyncDate }}</li>
                <li class="list-group-item">Total raw files: {{ viewState.rawFilesList.totalCount }}</li>
                <li class="list-group-item">Total glacier files: {{ viewState.rawFilesList.glacierCount }}</li>
                <li class="list-group-item">Total standard access files: {{ viewState.rawFilesList.standardCount }}</li>
            </ul>
            <div class="col-12 mt-4">
                <button class="btn btn-primary"
                    :disabled="viewState.rawFilesList.restoreDisabled || viewState.rawFilesList.loading"
                    @click="triggerRestore">Trigger restore glacier
                    files</button>
            </div>
        </div>
        <div class="col-6">
            <h4>Compiled files</h4>
            <div v-if="viewState.compiledFilesList.loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <p v-else-if="!viewState.compiledFilesList.dataList.length">No files available</p>
            <table v-else class="table">
                <thead>
                    <tr>
                        <th scope="row">Compiled At</th>
                        <th scope="col">Competition name</th>
                        <th scope="col">Number of rows</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="file in viewState.compiledFilesList.dataList">
                        <td scope="col">qweqe</td>
                        <td scope="col">Competition name</td>
                        <td scope="col">Number of rows</td>
                        <td scope="col">
                            <i @click="downloadFile(file.uuid)" class="bi bi-download"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { onBeforeMount, reactive } from 'vue';

const viewState = reactive({
    compiledFilesList: {
        loading: true,
        page: 1,
        dataList: [],
    },
    rawFilesList: {
        loading: true,
        restoreDisabled: false,
        totalCount: 0,
        glacierCount: 0,
        standardCount: 0,
        lastUpdatedFile: null,
        page: 1,
        dataList: [],
        lastSyncDate: null,
    },
    lastRestoreProcess: {
        startedAt: null,
        completedAt: null,
        status: null,
        totalFiles: 0,
        restored: 0,
    },
});

onBeforeMount(async () => {
    try {
        const { data } = await axios.get('/api/v1/data-explorer/raw-files');

        viewState.rawFilesList.glacierCount = data.glacierCount;
        viewState.rawFilesList.standardCount = data.standardCount;
        viewState.rawFilesList.totalCount = data.totalCount;
        viewState.rawFilesList.lastSyncDate = data.lastSyncDate;
        viewState.rawFilesList.loading = false;
    } catch (e) {
        console.error(e);
    }
});

const triggerRestore = async () => {
    viewState.rawFilesList.restoreDisabled = true;
    try {
        await axios.get('/api/v1/data-explorer/restore-all');

        setTimeout(() => {
            viewState.rawFilesList.restoreDisabled = false;
        }, 300000);
    } catch (e) {
        console.error(e);
        viewState.rawFilesList.restoreDisabled = false;
    }
}
</script>