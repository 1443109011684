<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <form>
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            v-model="loginForm.email">
                        <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1"
                            v-model="loginForm.password">
                    </div>

                    <button type="button" class="btn btn-primary" :disabled="loginForm.submitting"
                        @click="loginUser">Login</button>
                </form>
            </div>
        </div>
    </div>

</template>

<script setup>
import { useUsersStore } from '@/store/usersStore';
import axios from 'axios';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const loginForm = reactive({
    email: '',
    password: '',
    submitting: false,
});

const store = useUsersStore()

const loginUser = async () => {
    loginForm.submitting = true;
    try {
        const { data } = await axios.post('/api/v1/users/login', { email: loginForm.email, password: loginForm.password });

        store.setLoggedInUser(data);

        router.replace({ name: 'Home' });
    } finally {
        loginForm.submitting = false;
    }
}
</script>